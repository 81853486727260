import React from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  Button,
  FormInput,
  Switch,
  FormTextarea,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class RCadditionalDamage extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "RCadditionalDamage";
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;

    let tmpData = this.props.data;
    tmpData[name] = value;

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("rcAdditionalDamage", tmpData);
  };

  getSwitch = (title, name, isInput = true, isCustom) => {
    return (
      <Col xs={12}>
        <div className="d-flex mb-10">
          {isCustom ? (
            <FormInput
              className="mt-auto mb-auto mr-10"
              name={`name_${name}`}
              value={
                isCustom
                  ? this.props.data.customs[name].name
                  : this.props.data[`name_${name}`]
              }
              onChange={(e) => {
                if (isCustom) {
                  let tmpData = this.props.data;

                  tmpData.customs[name].name = e.target.value;

                  this.setData(tmpData);
                } else {
                  this.handleInputChange(e);
                }
              }}
            />
          ) : (
            <p className="mt-auto mb-auto mr-10">{title}</p>
          )}

          <Switch
            className="mt-auto mb-auto"
            value1="Non"
            value2="Oui"
            color1="#026fc2"
            color2="#f2711c"
            idxActive={
              isCustom
                ? this.props.data.customs[name].switch
                : this.props.data.hasOwnProperty(`switch_${name}`)
                ? this.props.data[`switch_${name}`]
                : 0
            }
            onChange={(type) => {
              let tmpData = this.props.data;

              if (isCustom) {
                tmpData.customs[name].switch = type;
              } else {
                tmpData[`switch_${name}`] = type;
              }
              this.setData(tmpData);
            }}
          />
          {isInput &&
          (this.props.data[`switch_${name}`] === 1 ||
            (isCustom && this.props.data.customs[name].switch) === 1) ? (
            <>
              <FormInput
                className="mt-auto mb-auto ml-10"
                name={`text_${name}`}
                value={
                  isCustom
                    ? this.props.data.customs[name].value
                    : this.props.data[`text_${name}`]
                }
                onChange={(e) => {
                  if (isCustom) {
                    let tmpData = this.props.data;

                    tmpData.customs[name].value = e.target.value;

                    this.setData(tmpData);
                  } else {
                    this.handleInputChange(e);
                  }
                }}
              />
              <span className="nowrap mt-auto mb-auto ml-10">
                € HT /{" "}
                {(
                  parseFloat(
                    (isCustom
                      ? this.props.data.customs[name].value
                      : this.props.data[`text_${name}`]) || 0
                  ) * 1.2
                ).toFixed(2)}{" "}
                € TTC
              </span>
            </>
          ) : null}
          {isCustom ? (
            <ButtonIcon className="red ml-10">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => {
                  let tmpData = this.props.data;

                  tmpData.customs.splice(name, 1);

                  this.setData(tmpData);
                }}
              />
            </ButtonIcon>
          ) : null}
        </div>
      </Col>
    );
  };

  getData = () => {
    return (
      <>
        <h3>Immobilisation du véhicule</h3>
        <Row>
          <Col xs={12} md={6}>
            <FormInput
              className="mb-10"
              type="number"
              title="A combien estimez-vous la durée de remise en état du véhicule ? (jour)"
              name="repair"
              value={this.props.repair}
              onChange={this.handleInputChange}
            />
          </Col>
          {this.getSwitch(
            "Le tiers a-t-il bénéficié d’un véhicule de prêt par le garage",
            "replacementVehicle",
            false
          )}
          {!this.props.data["switch_replacementVehicle"]
            ? this.getSwitch(
                "Dans la négative, le tiers a-t-il loué un véhicule de remplacement ",
                "elseReplacementVehicle",
                false
              )
            : null}
        </Row>
        <h3>Détail des frais</h3>
        <Row>
          {this.getSwitch(
            "Location véhicule remplacement",
            "replacementVehicleCost"
          )}
          {this.getSwitch("Montant remorquage", "breakdownAmount")}
          {this.getSwitch("Frais de gardiennage", "storageCosts")}
          {this.getSwitch("Frais de parking", "parkCosts")}
          {this.props.data &&
          this.props.data.customs &&
          this.props.data.customs.length
            ? this.props.data.customs.map((element, idx) => (
                <>{this.getSwitch("", idx, true, true)}</>
              ))
            : null}
          <Col xs={12}>
            <Button
              className="w-auto mb-10"
              text="Ajouter une ligne"
              onClick={() => {
                let tmpData = this.props.data;

                if (!tmpData.customs) {
                  tmpData.customs = [];
                }

                tmpData.customs.push({
                  name: "",
                  switch: 0,
                  value: "",
                });

                this.setData(tmpData);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
          <Col xs={12}>
            <FormTextarea
              className="mb-10"
              title="Observations"
              name="commentFees"
              value={this.props.data.commentFees}
              onChange={this.handleInputChange}
              rows={8}
            />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <CustomCard title="Frais annexes" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
