import axiosConfig from "../utils/axiosConfig";
import { getAll, insertAll } from "../db/folder";

const version_local = 7;

const refreshToken = (token, url, callback) => {
  if (navigator.onLine) {
    axiosConfig("/auth/refresh-token", {
      data: {
        token,
        url,
      },
    })
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  } else {
    callback({ success: false });
  }
};

const signout = (url, callback) => {
  if (navigator.onLine) {
    axiosConfig("/auth/signout", { data: { url } })
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  } else {
    callback({ success: false });
  }
};

const getDefault = (url, callback, data = {}) => {
  axiosConfig(url, { data })
    .then((res) => {
      callback(res.data);
    })
    .catch((e) => callback({ success: false }));
};

const getType = (id, callback) => {
  axiosConfig("/general/get-type", { data: { id } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getState = (id, callback) => {
  axiosConfig("/general/get-state", { data: { id } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const insertFolderAction = (folder, action, keyData, callback, date = null) => {
  axiosConfig("/general/insert-folder-action", {
    data: {
      folder,
      action,
      keyData,
      date,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

const getOptions = (callback) => {
  const functions = [
    { name: "agencies", url: "/general/get-agencies" },
    { name: "types", url: "/general/get-types" },
    { name: "states", url: "/general/get-states" },
    { name: "companies", url: "/general/get-companies" },
    { name: "appointmentTypes", url: "/general/get-appointment-types" },
    { name: "users", url: "/general/get-users" },
    { name: "regions", url: "/general/get-regions" },
  ];

  Promise.all(
    functions.map((element) => {
      return new Promise((resolve) => {
        getAll(element.name, (resulLocal) => {
          if (
            resulLocal.success &&
            parseInt(localStorage.getItem("version_local")) === version_local
          ) {
            resolve({ name: element.name, data: resulLocal.data });
          } else {
            getDefault(element.url, (result) => {
              if (result.success) {
                insertAll(result.data, element.name, () => {
                  localStorage.setItem("version_local", version_local);
                  resolve({ name: element.name, data: result.data });
                });
              } else {
                resolve({ name: element.name, data: [] });
              }
            });
          }
        });
      });
    })
  ).then((result) => {
    let data = {};

    result.forEach((element) => {
      data[element.name] = element.data;
    });

    callback({
      success: true,
      data,
    });
  });
};

const writeLog = (id, name, data, callback = () => {}) => {
  axiosConfig("/general/write-log", { data: { id, name, data } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  refreshToken,
  signout,
  getType,
  getState,
  insertFolderAction,
  getDefault,
  getOptions,
  writeLog,
};
